import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import parse from 'autosuggest-highlight/parse';
import throttle from "lodash/throttle";
import * as Yup from "yup";
import regex from "src/utils/regex";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
let placesService = null;
let map = null;

// function withGoogleMapScript(WrappedComponent) {
//   return function (props) {
//     const [isLoaded, setIsLoaded] = React.useState(false);
//     React.useEffect(() => {
//       const script = document.createElement("script");
//       script.src =
//         "https://maps.googleapis.com/maps/api/js?key=AIzaSyByJ8mHpDMsQYWH-ToZE5eLBvEouildEYs&libraries=places";
//       script.async = true;
//       script.setAttribute("id", "google-maps");
//       document.querySelector("head").append(script);
//       script.onload = () => {
//         setIsLoaded(true);
//       };
//     }, []);

//     return <>{isLoaded ? <WrappedComponent {...props} /> : null}</>;
//   };
// }

function GoogleMaps({
  handleCreateAddress,
  handleRemoveAddress,
  handleUpdateAddress,
  guarantorFieldErrors,
  setGuarantorFieldErrors,
  singleCustomerId,
  address,
  customerId,
  size,
  style,
  canRemoveAddress = true,
}) {
  const [value, setValue] = React.useState(
    address?.fullAddress || address || ""
  );
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyByJ8mHpDMsQYWH-ToZE5eLBvEouildEYs&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  React.useEffect(() => {
    if (window.google) {
      map = new window.google.maps.Map(document.querySelector("#google-maps"), {
        center: new window.google.maps.LatLng(0, 0),
        zoom: 15,
      });
    }
  }, [window.google]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    if (inputValue?.length <= 200) {
      let active = true;

      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }

      if (!placesService && map) {
        placesService = new window.google.maps.places.PlacesService(map);
      }

      if (!placesService) {
        return undefined;
      }

      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch({ input: inputValue }, (results) => {
        // console.log(results);
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }
  }, [value, inputValue, fetch]);

  const [fieldErrors, setFieldErrors] = React.useState({
    address: "",
  });

  const validationSchema = Yup.object({
    address: Yup.string()
      .required("address field is required")
      // .matches(regex.addressRegex, "Please enter valid address")
      .min(3, "Maximum of 3 characters")
      .max(200, "Maximum of 200 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      if (guarantorFieldErrors) {
        setGuarantorFieldErrors((prevErrors) => ({
          ...prevErrors,
          address: "",
        }));
      }
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      if (guarantorFieldErrors) {
        setGuarantorFieldErrors((prevErrors) => ({
          ...prevErrors,
          address: error.message,
        }));
      }

      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onChangeFn = async (name, newInputValue) => {
    let isValid = true;

    isValid =
      regex.addressRegex.test(newInputValue) && newInputValue?.length <= 200;

    if (!isValid) {
      await validateField(name, newInputValue);
      return;
    }

    setInputValue(newInputValue);
    setFieldErrors({ address: "" });
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      disableClearable={!canRemoveAddress}
      filterOptions={(x) => x}
      options={options}
      blurOnSelect={true}
      autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={value}
      name="address"
      style={style}
      onChange={async (event, newValue) => {
        if ((newValue === null || !newValue) && canRemoveAddress) {
          handleRemoveAddress(address);
          return;
        }

        if (newValue) {
          placesService.getDetails(
            {
              placeId: newValue?.place_id,
              // fields: ['formatted_address', 'adr_address'],
            },
            (place) => {
              // console.log({ place });
              // if (address && place) {
              //   setOptions(newValue ? [newValue, ...options] : options);
              //   setValue(newValue);
              //   handleCreateAddress({
              //     _id: address?._id,
              //     address: place,
              //   });
              //   setFieldErrors({ address: "" });
              //   return;
              // }
              if (address) {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleUpdateAddress({
                  _id: address?._id,
                  address: place,
                  customerId: customerId,
                });
                setFieldErrors({ address: "" });
                return;
              }

              if (place) {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleCreateAddress(place);
                setFieldErrors({ address: "" });
              }
            }
          );
        } else {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          setFieldErrors({ address: "" });
        }
      }}
      onInputChange={(event, newInputValue) => {
        onChangeFn("address", newInputValue);
        // console.log(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Address"
          fullWidth
          variant="filled"
          size="small"
          error={
            value
              ? false
              : (singleCustomerId === customerId &&
                  guarantorFieldErrors?.address) ||
                fieldErrors?.address
          }
          helperText={
            value
              ? false
              : (singleCustomerId === customerId &&
                  guarantorFieldErrors?.address) ||
                fieldErrors?.address
          }
          name="address"
          onChange={async (event) => {
            await validateField("address", event?.target?.value);
            try {
              if (fieldErrors["address"] === "") {
                return;
              }
            } catch (error) {
              const newErrors = {};
              error.inner.forEach((validationError) => {
                newErrors[validationError.path] = validationError.message;
              });
              setFieldErrors(newErrors);
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default GoogleMaps;

// export default withGoogleMapScript(GoogleMaps);
