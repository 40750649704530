import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { orange, red } from "@mui/material/colors";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import UnarchiveTwoToneIcon from "@mui/icons-material/UnarchiveTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import _, { debounce } from "lodash";
import moment from "moment";
import clsx from "clsx";
import { getApplicationTab, STATUS_CODE, ERRORS_MESSAGE } from "../../constants";
import { userSelector } from "../../store/slices/userSlice";
import {
  applicationsSelector,
  getAllApplications,
  saveValue,
  updateApplicationStatus,
} from "../../store/slices/applicationsSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
import ConfirmationDialog from "../customComponents/ConfirmationDialog";
import { getCloneApplication, userDetailsSelector } from "../../store/slices/applicationFormSlice";
import { GridCellModes } from "@mui/x-data-grid";
import StatusCell from "./StatusCell";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

export default function ApplicationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = React.useState("");
  const user = useSelector(userSelector);
  const { gettingClonedApplication } = useSelector(userDetailsSelector);
  const { applications, page, isLoading, statusFilter } = useSelector(applicationsSelector);
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const [params, setParams] = useState({ offset: 1 });

  const filterOptions = ["All", "Active", "Archived", "Mine"];
  const localStatusFilterRef = React.useRef("Active");

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [unarchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
  const [dialogHandleFunction, setDialogHandleFunction] = useState(() => { });
  const [applicationHumanIdToUpdate, setApplicationHumanIdToUpdate] = useState("");

  const masterViewColumns = [
    {
      field: "humanId",
      headerName: "ID",
      type: "string",
      width: 90,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Date",
      type: "string",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: user.userType === "master" && true,
      renderEditCell: (params) => (
        user.userType === "master" ? (
          <StatusCell params={params} updateApplicationStatus={updateApplicationStatusCell} />
        ) : (
          <></>
        )
      ),
    },
    {
      field: "applicationType",
      headerName: "Application Type",
      type: "string",
      width: 110,
      editable: false,
    },
    ...(user.userType === "master" ? [{
      field: "broker",
      headerName: "Broker",
      type: "string",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>
            {params.value.firstName} {params.value.lastName}
          </Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.org}</Typography>
        </div>
      ),
    }] : []),
    {
      field: "assetType",
      headerName: "Asset type",
      type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "assetValue",
      headerName: "Asset value",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 220,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "financeAmount",
      headerName: "Finance amount",
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      editable: false,
      disableClickEventBubbling: gettingClonedApplication,
      renderCell: (params) => (
        <Stack direction="row">
          <ButtonGroup
            variant="text"
            color="primary" // Use a theme color if needed
            sx={{
              "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                borderColor: "rgba(0, 0, 0, 0.20)", // Divider color
              },
            }}
            aria-label="action button group"
          >
            <Tooltip title="Clone" arrow>
              <Button
                color="primary"
                // variant="outlined"
                size="large"
                disabled={params?.id && gettingClonedApplication}
                onClick={(e) => {
                  e.stopPropagation();
                  handle.cloneApplication(params?.id);
                }}
              >
                <ContentCopyTwoToneIcon style={{ color: "#1d4164" }} />
              </Button>
            </Tooltip>
            {!/^archived$/i.test(params?.row?.status) && (
              <Tooltip title="Archive" arrow>
                <Button
                  disabled={/^archived$/i.test(params?.row?.status)}
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "archived"));
                    setApplicationHumanIdToUpdate(params?.row?.humanId);
                    setArchiveDialogOpen(true);
                  }}
                >
                  <ArchiveTwoToneIcon color="warning" />
                </Button>
              </Tooltip>
            )}
            {/^archived$/i.test(params?.row?.status) && (
              <Tooltip title="Unarchive" arrow>
                <Button
                  disabled={!/^archived$/i.test(params?.row?.status)}
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "workshop"));
                    setApplicationHumanIdToUpdate(params?.row?.humanId);
                    setUnarchiveDialogOpen(true);
                  }}
                >
                  <UnarchiveTwoToneIcon color="warning" />
                </Button>
              </Tooltip>
            )}

            <Tooltip title="Delete" arrow>
              <Button
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "deleted"));
                  setApplicationHumanIdToUpdate(params?.row?.humanId);
                  setDeleteDialogOpen(true);
                }}
              >
                <DeleteTwoToneIcon color="error" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
      ),
    },
  ];

  const handle = {
    getAllApplicationsFn: (userId, cursor, search, filter = "") => {
      dispatch(getAllApplications({ userId, cursor, search, requestedList: getApplicationTab.application, filter })); // isQuoteList: false
    },
    handleApplicationSerch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllApplicationsFn(user, params?.offset, searchTerm, localStatusFilterRef.current);
    },
    cloneApplication: (applicationId) => {
      dispatch(getCloneApplication(applicationId)).then(({ payload }) => {
        payload?.status_code === STATUS_CODE.success && navigate(`/application/${payload?.data?._id}`);
      });
    },
    updateApplicationStatus: (applicationId, status) => {
      dispatch(updateApplicationStatus({ applicationId, status })).then((res) => {
        if (res?.payload?.status === 200) {
          enqueueSnackbar(`Application ${status.toLowerCase()} successfully}`, {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetch_error_msg, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
      setDialogHandleFunction(() => { });
      handle.getAllApplicationsFn(user, params?.offset, searchTerm, statusFilter);
    },
    handleFilterChange: async (option) => {
      handle.getAllApplicationsFn(user, params?.offset, searchTerm, option);
      dispatch(saveValue({ statusFilter: option }));
      localStatusFilterRef.current = option;
    },
  };

  useEffect(() => {
    if (params?.offset) {
      handle.getAllApplicationsFn(user, params?.offset, searchTerm, statusFilter);
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(applications?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => applications?.totalDocs ?? prevRowCountState);
  }, [applications?.totalDocs]);

  // useEffect(() => {
  //   if (user) handle.getAllApplicationsFn(user, page || 1, searchTerm);
  // }, [user]);

  useEffect(() => {
    const transformApplication = (doc) => {
      const assetValueCheck = doc?.asset?.assetValue
        ? _.get(doc, "asset.assetValue").toLocaleString(
          "en-US",
          dollarStringOptions
        )
        : "No Value";

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck;
      const financeAmount = _.get(doc, "asset.assetValue", 0) -
        _.get(doc, "loanDetails.deposit", 0) -
        _.get(doc, "loanDetails.tradeIn", 0) +
        _.get(doc, "loanDetails.payout", 0)

      const capitalizedStatus =
        _.get(doc, "status", "").charAt(0).toUpperCase() +
        _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType =
        _.get(doc, "applicationType", "").charAt(0).toUpperCase() +
        _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          financeAmount: financeAmount ? financeAmount.toLocaleString("en-US", dollarStringOptions) : 'Not entered',
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation[0].name"),
          },
          status: capitalizedStatus,
        },
        _.identity,
      );
    };

    const transformedRows = _.map(_.get(applications, "docs"), transformApplication);
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),
  }, [applications]);


  const debounced = useCallback(
    debounce(handle.handleApplicationSerch, 500),
    []
  );

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = React.useCallback((params, event) => {
    // console.log({ params })
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const updateApplicationStatusCell = ({ _id, status }) => {
    const updatedRows = rows.map((row) =>
      row.id === _id ? { ...row, status: status } : row
    );
    setRows(updatedRows);
  };

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid
        item
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          display: "flex",
          alignItems: "center",
          columnGap: "40px",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
          }}
        >
          Applications
        </Typography>
        <div className={classes.root}>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <Input
              className="input-border-bottom"
              id="application-search"
              autoComplete={false}
              variant="filled"
              placeholder="Search…"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debounced(e.target.value, 1000);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchTerm && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchTerm("");
                        searchTerm !== "" && debounced("", 1000);
                      }}
                      color="secondary"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </div>
        <ButtonGroup variant="outlined" color="secondary">
          {filterOptions.map((option) => (
            <Button
              key={option}
              size="small"
              onClick={() => {
                handle.handleFilterChange(option);
              }}
              variant={statusFilter === option ? "contained" : "outlined"}
            >
              {option}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>

      <Grid item>
        <DataGridTable
          onCellClick={(params, event) => {
            console.log("params", params)
            // event.defaultMuiPrevented = true;
            if (params.field !== "status") return navigate(`/application/${params.id}`);
            handleCellClick(params, event);
          }}
          onRowClick={() => { }}
          data={rows || []}
          // columns={user.userType === "master" ? masterViewColumns : brokerColumns}
          columns={masterViewColumns}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
          onCellEditStop={handleCellEditCommit}
        />
      </Grid>
      <ConfirmationDialog
        isOpen={archiveDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setArchiveDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: orange[500] }}>ARCHIVE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"warning"}
        proceedBtnText={"Archive"}
      />
      <ConfirmationDialog
        isOpen={deleteDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setDeleteDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: red[500] }}>DELETE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"error"}
        proceedBtnText={"Delete"}
      />
      <ConfirmationDialog
        isOpen={unarchiveDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setUnarchiveDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: orange[500] }}>UNARCHIVE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"warning"}
        proceedBtnText={"Unarchive"}
      />
    </Grid>
  );
}
